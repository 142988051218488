import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';

const HomePage = () => (
  <>
    <Helmet>
      <title>Home | Noxan Admin</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        justifyContent: 'left',
        py: 3
      }}
    >
      <Container maxWidth="sm">
        <Typography color="textPrimary" variant="h3">
          Welcome to Noxan Admin!
        </Typography>
      </Container>
    </Box>
  </>
);

export default HomePage;
