/* eslint-disable spaced-comment */
//import config from 'src/config.json';
import config from 'src/config.prod.json';
import { getSessionId } from 'src/utils/utils';
import { handleResponse, handleError } from './apiUtils';

const baseUrl = `${config.SERVER_URL}/report`;

export async function getSubmissions(jobId, request) {
  const targetUrl = `${baseUrl}/submission/${jobId}`;
  return fetch(targetUrl, {
    method: 'POST',
    headers: {
      Authorization: config.BASIC_AUTH,
      sessionId: getSessionId(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTimesheets(jobId, request) {
  const targetUrl = `${baseUrl}/timesheet/${jobId}`;
  return fetch(targetUrl, {
    method: 'POST',
    headers: {
      Authorization: config.BASIC_AUTH,
      sessionId: getSessionId(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(handleResponse)
    .catch(handleError);
}
