/* eslint-disable import/prefer-default-export */
export const trolleyStores = [
  {
    id: 1185,
    storeName: 'Camden',
    phone: '(02) 4651 4400',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'WMWV+CC Camden, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:08.71+08:00',
    modified: null,
    address: {
      id: 2,
      addressType: 0,
      buildingName: null,
      line1: '35 Oxley St',
      line2: null,
      suburb: 'Camden',
      postcode: '2570',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.05418,
      longitude: 150.693726,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1172,
    storeName: 'Glendale',
    phone: '(02) 4902 2717',
    description: null,
    brand: 'Supermarket',
    plusCodes: '3J8R+59 Glendale, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:12.577+08:00',
    modified: null,
    address: {
      id: 13,
      addressType: 0,
      buildingName: null,
      line1: '387 Lake Rd',
      line2: null,
      suburb: 'Glendale',
      postcode: '2285',
      state: 'NSW',
      country: 'Australia',
      latitude: -32.934612,
      longitude: 151.640837,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1204,
    storeName: 'Ingleburn',
    phone: '(02) 8785 3624',
    description: null,
    brand: 'Supermarket',
    plusCodes: '2V27+8J Ingleburn, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:09.083+08:00',
    modified: null,
    address: {
      id: 3,
      addressType: 0,
      buildingName: 'Ingleburn Town Centre',
      line1: 'Nardoo St & Norfolk Street',
      line2: null,
      suburb: 'Ingleburn',
      postcode: '2565',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.999095,
      longitude: 150.863963,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1347,
    storeName: 'Kellyville',
    phone: '(02) 9677 6447',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7XPC+PC Kellyville, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:11.407+08:00',
    modified: null,
    address: {
      id: 10,
      addressType: 0,
      buildingName: null,
      line1: '88 Wrights Rd',
      line2: null,
      suburb: 'Kellyville',
      postcode: '2155',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.713169,
      longitude: 150.971051,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1163,
    storeName: 'Kiama',
    phone: '(02) 4232 6400',
    description: null,
    brand: 'Supermarket',
    plusCodes: '8VM2+53 Kiama, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:09.74+08:00',
    modified: null,
    address: {
      id: 5,
      addressType: 0,
      buildingName: 'Kiama View Shopping Centre',
      line1: '143 Terralong St',
      line2: null,
      suburb: 'Kiama',
      postcode: '2533',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.666826,
      longitude: 150.850133,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1108,
    storeName: 'Marayong',
    phone: '(02) 9677 6420',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7V5P+X7 Marayong, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:11.8+08:00',
    modified: null,
    address: {
      id: 11,
      addressType: 0,
      buildingName: 'Quakers Court',
      line1: 'Falmouth Rd & Quakers Rd',
      line2: null,
      suburb: 'Marayong',
      postcode: '2763',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.740307,
      longitude: 150.885934,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1934,
    storeName: 'Mortdale',
    phone: '(02) 8565 9309',
    description: null,
    brand: 'Supermarket',
    plusCodes: '23J7+HX Mortdale, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:10.393+08:00',
    modified: null,
    address: {
      id: 7,
      addressType: 0,
      buildingName: null,
      line1: '84D Roberts Ave',
      line2: null,
      suburb: 'Mortdale',
      postcode: '2223',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.96866,
      longitude: 151.064897,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1004,
    storeName: 'Punchbowl',
    phone: '(02) 8565 9306',
    description: null,
    brand: 'Supermarket',
    plusCodes: '33F5+G8 Punchbowl, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:09.42+08:00',
    modified: null,
    address: {
      id: 4,
      addressType: 0,
      buildingName: null,
      line1: '1/9 The Boulevarde',
      line2: null,
      suburb: 'Punchbowl',
      postcode: '2196',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.926131,
      longitude: 151.058125,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1174,
    storeName: 'Salamander Bay',
    phone: '(02) 4919 5000',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7484+2P Salamander Bay, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:12.903+08:00',
    modified: null,
    address: {
      id: 14,
      addressType: 0,
      buildingName: null,
      line1: '2 Town Centre Cct',
      line2: null,
      suburb: 'Salamander Bay',
      postcode: '2317',
      state: 'NSW',
      country: 'Australia',
      latitude: -32.73492,
      longitude: 152.106773,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1197,
    storeName: 'Shellharbour',
    phone: '(02) 4297 8376',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'CRPQ+7P Shellharbour, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:10.077+08:00',
    modified: null,
    address: {
      id: 6,
      addressType: 0,
      buildingName: null,
      line1: '211 Lake Entrance Rd',
      line2: null,
      suburb: 'Shellharbour',
      postcode: '2529',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.564229,
      longitude: 150.839253,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1249,
    storeName: 'Thornleigh',
    phone: '(02) 9450 6709',
    description: null,
    brand: 'Supermarket & Dan Murphys',
    plusCodes: '739J+CW Thornleigh, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:11.053+08:00',
    modified: null,
    address: {
      id: 9,
      addressType: 0,
      buildingName: 'Marketplace',
      line1: '261 Pennant Hills Rd',
      line2: null,
      suburb: 'Thornleigh',
      postcode: '2120',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.731168,
      longitude: 151.081303,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1344,
    storeName: 'Toronto',
    phone: '(02) 4902 2732',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'XHPW+59 Toronto, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:12.223+08:00',
    modified: null,
    address: {
      id: 12,
      addressType: 0,
      buildingName: null,
      line1: 'Brighton Ave',
      line2: null,
      suburb: 'Toronto',
      postcode: '2283',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.014634,
      longitude: 151.595973,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1621,
    storeName: 'Warriewood',
    phone: '(02) 9450 6718',
    description: null,
    brand: 'Supermarket',
    plusCodes: '873W+R4 Warriewood, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:10.727+08:00',
    modified: null,
    address: {
      id: 8,
      addressType: 0,
      buildingName: null,
      line1: '12 Jacksons Rd',
      line2: null,
      suburb: 'Warriewood',
      postcode: '2102',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.695444,
      longitude: 151.295238,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  }
];

export const cleaningStores = [
  {
    id: 1620,
    storeName: '333-George Street',
    phone: '(02) 85659356',
    description: null,
    brand: 'Metro',
    plusCodes: '46M4+7Q Sydney, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:25.86+08:00',
    modified: null,
    address: {
      id: 132,
      addressType: 0,
      buildingName: null,
      line1: '333 George St',
      line2: null,
      suburb: 'Sydney',
      postcode: '2000',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.86678,
      longitude: 151.20687,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1265,
    storeName: '65 York St',
    phone: '(02) 8565 9327',
    description: null,
    brand: 'Metro',
    plusCodes: '46J4+V7 Sydney, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:21.583+08:00',
    modified: null,
    address: {
      id: 125,
      addressType: 0,
      buildingName: null,
      line1: '65 York St',
      line2: null,
      suburb: 'Sydney',
      postcode: '2000',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.86789,
      longitude: 151.20571,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1050,
    storeName: 'Alexandria',
    phone: '(02) 8565 9353',
    description: null,
    brand: 'Supermarket',
    plusCodes: '35XV+5W Alexandria, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:13.07+08:00',
    modified: null,
    address: {
      id: 111,
      addressType: 0,
      buildingName: null,
      line1: '10 Fountain St',
      line2: null,
      suburb: 'Alexandria',
      postcode: '2015',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.90241,
      longitude: 151.19502,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1188,
    storeName: 'Balmain',
    phone: '(02) 8565 9203',
    description: null,
    brand: 'Supermarket',
    plusCodes: '45RJ+RF Balmain, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:14.327+08:00',
    modified: null,
    address: {
      id: 113,
      addressType: 0,
      buildingName: null,
      line1: '276 Darling St',
      line2: null,
      suburb: 'Balmain',
      postcode: '2041',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.85788,
      longitude: 151.18112,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1590,
    storeName: 'Banana Joes',
    phone: '(02) 8736 7458',
    description: null,
    brand: 'Metro',
    plusCodes: '35Q4+F5 Marrickville, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:29.47+08:00',
    modified: null,
    address: {
      id: 138,
      addressType: 0,
      buildingName: null,
      line1: '258 Illawarra Rd',
      line2: null,
      suburb: 'Marrickville',
      postcode: '2204',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.9113,
      longitude: 151.15541,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1364,
    storeName: 'Beecroft',
    phone: '(02) 9450 6727',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7328+72 Beecroft, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:16.74+08:00',
    modified: null,
    address: {
      id: 117,
      addressType: 0,
      buildingName: 'Beecroft Place',
      line1: '18 Hannah St',
      line2: null,
      suburb: 'Beecroft',
      postcode: '2119',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.74922,
      longitude: 151.06502,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1545,
    storeName: 'Bondi Beach',
    phone: '(02) 8565 9324',
    description: null,
    brand: 'Metro',
    plusCodes: '476G+C6 Bondi Beach, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:24.65+08:00',
    modified: null,
    address: {
      id: 130,
      addressType: 0,
      buildingName: null,
      line1: '184 Campbell Parade',
      line2: null,
      suburb: 'Bondi Beach',
      postcode: '2026',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.8889,
      longitude: 151.27557,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1391,
    storeName: 'Brookvale',
    phone: '',
    description: null,
    brand: 'CFC',
    plusCodes: '67P6+74 Brookvale, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:30.693+08:00',
    modified: null,
    address: {
      id: 140,
      addressType: 0,
      buildingName: null,
      line1: '114/120 Old Pittwater Rd',
      line2: null,
      suburb: 'Brookvale',
      postcode: '2100',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.76423,
      longitude: 151.2603,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1392,
    storeName: 'Bulli',
    phone: '(02) 4276 6040',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'MW77+86 Bulli, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:17.34+08:00',
    modified: null,
    address: {
      id: 118,
      addressType: 0,
      buildingName: null,
      line1: '5-9 Molloy St',
      line2: null,
      suburb: 'Bulli',
      postcode: '2516',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.33665,
      longitude: 150.91305,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1385,
    storeName: 'Cameron Ave',
    phone: '61329326',
    description: null,
    brand: 'Metro',
    plusCodes: 'Q35C+WQ Belconnen, Australian Capital Territory',
    disabled: null,
    created: '2021-03-19T23:56:20.397+08:00',
    modified: null,
    address: {
      id: 123,
      addressType: 0,
      buildingName: null,
      line1: '1/6 Grazier La',
      line2: null,
      suburb: 'Belconnen',
      postcode: '2617',
      state: 'ACT',
      country: 'Australia',
      latitude: -35.23999,
      longitude: 149.07195,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1034,
    storeName: 'Canterbury',
    phone: '(02) 8565 9347',
    description: null,
    brand: 'Supermarket',
    plusCodes: '34Q9+45 Canterbury, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:12.46+08:00',
    modified: null,
    address: {
      id: 110,
      addressType: 0,
      buildingName: null,
      line1: '2A Charles Street',
      line2: null,
      suburb: 'Canterbury',
      postcode: '2193',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.91255,
      longitude: 151.11804,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1556,
    storeName: 'Cranebrook',
    phone: '(02) 4723 2519',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7PJ6+47 Cranebrook, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:09.607+08:00',
    modified: null,
    address: {
      id: 106,
      addressType: 0,
      buildingName: null,
      line1: '80 - 98 Borrowdale Way',
      line2: null,
      suburb: 'Cranebrook',
      postcode: '2749',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.7197,
      longitude: 150.71072,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1073,
    storeName: 'Dickson',
    phone: '(02) 6132 9810',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'P4XQ+WH Dickson, Australian Capital Territory',
    disabled: null,
    created: '2021-03-19T23:56:13.71+08:00',
    modified: null,
    address: {
      id: 112,
      addressType: 0,
      buildingName: null,
      line1: '1 Dickson Pl',
      line2: null,
      suburb: 'Dickson',
      postcode: '2602',
      state: 'ACT',
      country: 'Australia',
      latitude: -35.24993,
      longitude: 149.13861,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1416,
    storeName: 'Double Bay',
    phone: '(02) 8736 7472',
    description: null,
    brand: 'Supermarket',
    plusCodes: '46CV+PW Double Bay, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:17.93+08:00',
    modified: null,
    address: {
      id: 119,
      addressType: 0,
      buildingName: 'Kiaora Lane and',
      line1: 'Kiaora Rd',
      line2: null,
      suburb: 'Double Bay',
      postcode: '2028',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.87802,
      longitude: 151.24475,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1336,
    storeName: 'Emerald Hills',
    phone: '(02) 8785 3673',
    description: null,
    brand: 'Supermarket',
    plusCodes: '2Q3R+CV Leppington, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:10.903+08:00',
    modified: null,
    address: {
      id: 108,
      addressType: 0,
      buildingName: null,
      line1: '5 Emerald Hills Blvd',
      line2: null,
      suburb: 'Leppington',
      postcode: '2179',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.99646,
      longitude: 150.79244,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1293,
    storeName: 'Fairy Meadow',
    phone: '(02) 4276 6021',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'JV5V+9G Fairy Meadow, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:16.143+08:00',
    modified: null,
    address: {
      id: 116,
      addressType: 0,
      buildingName: null,
      line1: '66 Princes Hwy',
      line2: null,
      suburb: 'Fairy Meadow',
      postcode: '2519',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.39154,
      longitude: 150.89383,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1661,
    storeName: 'Franklin',
    phone: '(02) 6132 9305',
    description: null,
    brand: 'Metro',
    plusCodes: 'Q4XX+XH Franklin, Australian Capital Territory',
    disabled: null,
    created: '2021-03-19T23:56:19.767+08:00',
    modified: null,
    address: {
      id: 122,
      addressType: 0,
      buildingName: '51 Nullarbor Avenue',
      line1: 'Cnr Flemington Rd',
      line2: null,
      suburb: 'Franklin',
      postcode: '2913',
      state: 'ACT',
      country: 'Australia',
      latitude: -35.20001,
      longitude: 149.14886,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1638,
    storeName: 'Green Square',
    phone: '(02) 8565 9365',
    description: null,
    brand: 'Metro',
    plusCodes: '36V3+GQ Zetland, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:27.067+08:00',
    modified: null,
    address: {
      id: 134,
      addressType: 0,
      buildingName: null,
      line1: '20 Ebsworth St',
      line2: null,
      suburb: 'Zetland',
      postcode: '2017',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.90605,
      longitude: 151.20447,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1284,
    storeName: 'Hurstville',
    phone: '(02) 8565 9341',
    description: null,
    brand: 'Supermarket',
    plusCodes: '24M4+Q9 Hurstville, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:15.53+08:00',
    modified: null,
    address: {
      id: 115,
      addressType: 0,
      buildingName: '223 Cnr Park &',
      line1: 'Cross St',
      line2: null,
      suburb: 'Hurstville',
      postcode: '2220',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.96552,
      longitude: 151.10592,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1270,
    storeName: 'Kings Cross',
    phone: '(02) 8565 9381',
    description: null,
    brand: 'Metro',
    plusCodes: '46GF+73 Potts Point, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:22.23+08:00',
    modified: null,
    address: {
      id: 126,
      addressType: 0,
      buildingName: null,
      line1: '111-139 Darlinghurst Rd',
      line2: null,
      suburb: 'Potts Point',
      postcode: '2011',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.87431,
      longitude: 151.222526,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1845,
    storeName: 'Kirribilli',
    phone: '(02) 8565 9390',
    description: null,
    brand: 'Metro',
    plusCodes: '5637+H5 Kirribilli, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:28.873+08:00',
    modified: null,
    address: {
      id: 137,
      addressType: 0,
      buildingName: null,
      line1: '23 Broughton St',
      line2: null,
      suburb: 'Kirribilli',
      postcode: '2061',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.84609,
      longitude: 151.21298,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1800,
    storeName: 'Mascot',
    phone: '',
    description: null,
    brand: 'CFC',
    plusCodes: '35FG+W7 Mascot, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:31.307+08:00',
    modified: null,
    address: {
      id: 141,
      addressType: 0,
      buildingName: null,
      line1: '306 Coward St',
      line2: null,
      suburb: 'Mascot',
      postcode: '2020',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.92513,
      longitude: 151.17566,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1442,
    storeName: 'Mascot',
    phone: '(02) 8565 9333',
    description: null,
    brand: 'Supermarket',
    plusCodes: '35GP+R8 Mascot, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:18.533+08:00',
    modified: null,
    address: {
      id: 120,
      addressType: 0,
      buildingName: null,
      line1: '55 Church Ave',
      line2: null,
      suburb: 'Mascot',
      postcode: '2020',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.9229,
      longitude: 151.18578,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1479,
    storeName: 'Minto',
    phone: '(02) 8785 3670',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'XR9X+RR Minto, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:19.147+08:00',
    modified: null,
    address: {
      id: 121,
      addressType: 0,
      buildingName: null,
      line1: '10 Brookfield Rd',
      line2: null,
      suburb: 'Minto',
      postcode: '2566',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.03022,
      longitude: 150.8496,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1816,
    storeName: 'North Sydney',
    phone: '(02) 9305 1097',
    description: null,
    brand: 'Metro',
    plusCodes: '5664+WP North Sydney, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:28.28+08:00',
    modified: null,
    address: {
      id: 136,
      addressType: 0,
      buildingName: null,
      line1: '100 Miller St',
      line2: null,
      suburb: 'North Sydney',
      postcode: '2060',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.8376,
      longitude: 151.20677,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1329,
    storeName: 'Paddington',
    phone: '(02) 8565 9369',
    description: null,
    brand: 'Metro',
    plusCodes: '469H+64 Paddington, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:22.853+08:00',
    modified: null,
    address: {
      id: 127,
      addressType: 0,
      buildingName: null,
      line1: '220 Glenmore Rd',
      line2: null,
      suburb: 'Paddington',
      postcode: '2021',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.88175,
      longitude: 151.22777,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1665,
    storeName: 'Parramatta Station',
    phone: '(02) 8633 2950',
    description: null,
    brand: 'Metro',
    plusCodes: '52J3+X3 Parramatta, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:27.68+08:00',
    modified: null,
    address: {
      id: 135,
      addressType: 0,
      buildingName: 'Shop 1156',
      line1: 'L1 Westfield',
      line2: null,
      suburb: 'Parramatta',
      postcode: '2150',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.81731,
      longitude: 151.00266,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1211,
    storeName: 'Pitt St Mall',
    phone: '(02) 9308 7388',
    description: null,
    brand: 'Metro',
    plusCodes: '46J4+9W Sydney, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:20.99+08:00',
    modified: null,
    address: {
      id: 124,
      addressType: 0,
      buildingName: null,
      line1: '400 George St',
      line2: null,
      suburb: 'Sydney',
      postcode: '2000',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.86899,
      longitude: 151.20738,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1643,
    storeName: 'Prestons',
    phone: '(02) 8785 3654',
    description: null,
    brand: 'Supermarket',
    plusCodes: '2VX4+44 Prestons, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:10.29+08:00',
    modified: null,
    address: {
      id: 107,
      addressType: 0,
      buildingName: null,
      line1: '1975-1985 Camden Valley Way',
      line2: null,
      suburb: 'Prestons',
      postcode: '2170',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.95211,
      longitude: 150.85536,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1004,
    storeName: 'Punchbowl',
    phone: '(02) 8565 9306',
    description: null,
    brand: 'Supermarket',
    plusCodes: '33F5+G8 Punchbowl, New South Wales',
    disabled: null,
    created: '2020-09-06T00:24:09.42+08:00',
    modified: null,
    address: {
      id: 4,
      addressType: 0,
      buildingName: null,
      line1: '1/9 The Boulevarde',
      line2: null,
      suburb: 'Punchbowl',
      postcode: '2196',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.926131,
      longitude: 151.058125,
      created: '2020-09-06T00:24:08.557+08:00',
      modified: null
    }
  },
  {
    id: 1449,
    storeName: 'Pyrmont',
    phone: '(02) 8736 7447',
    description: null,
    brand: 'Metro',
    plusCodes: '45HV+W4 Pyrmont, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:23.46+08:00',
    modified: null,
    address: {
      id: 128,
      addressType: 0,
      buildingName: null,
      line1: '63 Miller St',
      line2: null,
      suburb: 'Pyrmont',
      postcode: '2009',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.87022,
      longitude: 151.19287,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1631,
    storeName: 'Randwick',
    phone: '(02) 8565 9248',
    description: null,
    brand: 'Metro',
    plusCodes: '36PR+38 Randwick, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:26.477+08:00',
    modified: null,
    address: {
      id: 133,
      addressType: 0,
      buildingName: null,
      line1: '73 Belmore Rd',
      line2: null,
      suburb: 'Randwick',
      postcode: '2031',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.91473,
      longitude: 151.24076,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1901,
    storeName: 'Rose Bay',
    phone: '(02) 8565 9315',
    description: null,
    brand: 'Metro',
    plusCodes: '47H9+P9 Rose Bay, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:30.07+08:00',
    modified: null,
    address: {
      id: 139,
      addressType: 0,
      buildingName: null,
      line1: '757 New S Head Rd',
      line2: null,
      suburb: 'Rose Bay',
      postcode: '2029',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.8707,
      longitude: 151.26846,
      created: '2021-03-19T23:56:09.463+08:00',
      modified: null
    }
  },
  {
    id: 1577,
    storeName: 'Rozelle',
    phone: '(02) 8736 7475',
    description: null,
    brand: 'Metro',
    plusCodes: '45QC+GH Rozelle, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:25.26+08:00',
    modified: null,
    address: {
      id: 131,
      addressType: 0,
      buildingName: null,
      line1: '605 Darling St',
      line2: null,
      suburb: 'Rozelle',
      postcode: '2039',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.86121,
      longitude: 151.17142,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1603,
    storeName: 'Spring Farm',
    phone: '(02) 4651 4403',
    description: null,
    brand: 'Supermarket',
    plusCodes: 'WPJH+2F Spring Farm, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:11.517+08:00',
    modified: null,
    address: {
      id: 109,
      addressType: 0,
      buildingName: null,
      line1: '254 Richardson Rd',
      line2: null,
      suburb: 'Spring Farm',
      postcode: '2570',
      state: 'NSW',
      country: 'Australia',
      latitude: -34.06987,
      longitude: 150.72868,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1464,
    storeName: 'The Kitchen - Double Bay',
    phone: '(02) 8565 9387',
    description: null,
    brand: 'Metro',
    plusCodes: '46CV+RV Double Bay, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:24.04+08:00',
    modified: null,
    address: {
      id: 129,
      addressType: 0,
      buildingName: 'Cnr Kiaora Lane and',
      line1: 'Kiaora Rd',
      line2: null,
      suburb: 'Double Bay',
      postcode: '2028',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.87784,
      longitude: 151.24471,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  },
  {
    id: 1272,
    storeName: 'The Ponds',
    phone: '(02) 9677 6462',
    description: null,
    brand: 'Supermarket',
    plusCodes: '7WV6+JG The Ponds, New South Wales',
    disabled: null,
    created: '2021-03-19T23:56:14.927+08:00',
    modified: null,
    address: {
      id: 114,
      addressType: 0,
      buildingName: 'Cnr Riverbank Drive and',
      line1: 'The Ponds Blvd',
      line2: null,
      suburb: 'The Ponds',
      postcode: '2769',
      state: 'NSW',
      country: 'Australia',
      latitude: -33.70585,
      longitude: 150.91134,
      created: '2021-03-19T23:56:09.46+08:00',
      modified: null
    }
  }
];
