/* eslint-disable eqeqeq */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters } from 'react-table';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { CSVLink } from 'react-csv';

export default function ReportTable({
  stores,
  columns,
  data,
  jobType,
  reportType,
  startDate,
  endDate
}) {
  const [selectedStore, setSelectedStore] = useState('');

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    rows,
    setFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('storeName', value);
    setSelectedStore(value);
  };

  const getDataForCvs = (reportData) => {
    const cvsData =
      reportType === 'Signoff'
        ? reportData
            .filter((item) => !selectedStore || selectedStore == item.storeName)
            .map((item) => {
              return {
                Store: item.storeName,
                Date: item.date,
                Staff: item.staffName,
                SignoffStart: item.signoffStart,
                SignoffEnd: item.signoffEnd
              };
            })
        : reportData
            .filter((item) => !selectedStore || selectedStore == item.storeName)
            .map((item) => {
              return {
                Store: item.storeName,
                Date: item.date,
                StaffName: item.staffName,
                ShiftStart: item.shiftStart,
                TimesheetStart: item.timesheetStart,
                SignoffStart: item.signoffStart,
                ShiftEnd: item.shiftEnd,
                TimesheetEnd: item.timesheetEnd,
                SignoffEnd: item.signoffEnd,
                ManualStartReason: item.manualStartReason,
                ManualEndReason: item.manualEndReason,
                timesheetNote: item.timesheetNote,
                SignoffNote: item.signoffNote
              };
            });
    return cvsData;
  };

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  console.log('[ReportTable] rendering...');
  return (
    <Card>
      <Card>
        <CardContent>
          <Box sx={{ mt: 1 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex'
              }}
            >
              {stores && stores.length > 0 && (
                <Box sx={{ marginLeft: 2 }}>
                  <FormControl>
                    <InputLabel htmlFor="store-select" sx={{ marginLeft: 2 }}>
                      Filter by Store
                    </InputLabel>
                    <Select
                      native
                      onChange={handleFilterChange}
                      value={selectedStore}
                      inputProps={{
                        name: 'stores',
                        id: 'store-select'
                      }}
                      sx={{ marginLeft: 2 }}
                    >
                      <option aria-label="None" value="" />
                      {stores.map(({ storeName }) => (
                        <option value={storeName}>{storeName}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Button
                sx={{ marginLeft: 3, color: '#fff' }}
                color="info"
                variant="contained"
              >
                <CSVLink
                  data={getDataForCvs(data)}
                  filename={`Noxan-Report_${
                    selectedStore || 'AllStores'
                  }-${jobType}-${reportType}_${startDate}_${endDate}.csv`}
                  style={{ color: '#fff', fontWeight: 'bold' }}
                >
                  Export
                </CSVLink>
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800, marginLeft: 2 }}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                return headerGroup.headers.length === 1 ? (
                  ''
                ) : (
                  <TableRow>
                    {headerGroup.headers.map((column) => (
                      <TableCell sx={{ fontSize: 15 }}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow hover key={row.original.summaryId}>
                    {row.cells.map((cell) => (
                      <TableCell sx={{ minWidth: 130 }}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

ReportTable.propTypes = {
  stores: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  jobType: PropTypes.string,
  reportType: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};
