/* eslint-disable prefer-destructuring */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
import 'date-fns';
import { Helmet } from 'react-helmet';
import { Box, Button, Container, Typography } from '@material-ui/core';

import React, { useState } from 'react';
import { getSubmissions, getTimesheets } from 'src/api/reportApi';
import ReportTable from 'src/components/report/ReportTable';
import { signoffColumnList } from 'src/components/report/signoffColumnList';
import { timesheetColumnList } from 'src/components/report/timesheetColumnList';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  toIsoDate,
  getWeeklyReportStartDate,
  getWeeklyReportEndDate
} from 'src/utils/utils';
import text from 'src/constants/text';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { trolleyStores, cleaningStores } from 'src/components/report/stores';
import 'src/style/datepicker.css';

const ReportPage = () => {
  const [isRetrieving, setIsRetrieving] = useState(false);
  const [state, setState] = useState({
    reportData: [],
    reportType: '',
    jobId: '',
    startDate: getWeeklyReportStartDate(new Date()),
    endDate: getWeeklyReportEndDate(new Date()),
    alertOpen: false,
    alertMessage: '',
    alertSeverity: 'error'
  });

  const openAlert = (message, severity) => {
    setState({
      ...state,
      alertOpen: true,
      alertSeverity: severity || 'error',
      alertMessage: message
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({
      ...state,
      alertOpen: false,
      alertMessage: ''
    });
  };

  const handleSelectChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
      reportData: [],
      alertOpen: false,
      alertMessage: ''
    });
  };

  const handleStartDateChange = (startDate) => {
    setState({
      ...state,
      reportData: [],
      startDate
    });
  };

  const handleEndDateChange = (endDate) => {
    setState({
      ...state,
      reportData: [],
      endDate
    });
  };

  const isReportTypeSignoff = () => state.reportType === 'Signoff';

  const retrieveReport = async (e) => {
    e.preventDefault();
    console.log('[ReportPage.retrieveReport] starts');

    if (!state.jobId || !state.reportType) {
      console.log(
        `[ReportPage.retrieveReport] invalid request: jobId: ${state.jobId}, reportType: ${state.reportType}`
      );
      openAlert('Please select all required fields!');
      return;
    }

    setIsRetrieving(true);

    try {
      console.log(
        `[ReportPage.retrieveReport] get report: jobId: ${
          state.jobId
        }, reportType: ${state.reportType}, startDate: ${toIsoDate(
          state.startDate
        )}, endDate: ${toIsoDate(state.endDate)}`
      );
      const request = { startDate: state.startDate, endDate: state.endDate };
      const response = isReportTypeSignoff()
        ? await getSubmissions(state.jobId, request)
        : await getTimesheets(state.jobId, request);

      if (response && response.data) {
        setState({
          ...state,
          reportData: response.data
        });
        if (response.data.length === 0) {
          openAlert('No matching data found', 'info');
        }
        console.log(
          '[ReportPage.retrieveReport] report.count: ' + response.data.length
        );
      } else if (response && response.error) {
        console.log(
          '[ReportPage.retrieveReport] login returned error: ' +
            response.error.code
        );
        openAlert(text.common.generalErrorMessage);
      } else {
        console.log(
          '[ReportPage.retrieveReport] login returned invalid response'
        );
        openAlert(text.common.generalErrorMessage);
      }
    } catch (error) {
      console.log('[ReportPage.retrieveReport] error: ');
      console.log({ error });
      openAlert(text.common.generalErrorMessage);
    }
    setIsRetrieving(false);
  };

  console.log('[ReportPage] rendering...');
  return (
    <>
      <Helmet>
        <title>Reports | Noxan Admin</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          justifyContent: 'left',
          py: 3
        }}
      >
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h3">
            Reports
          </Typography>
        </Container>

        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex',
              marginTop: 5,
              marginLeft: 5
            }}
          >
            <FormControl>
              <InputLabel htmlFor="jobId-select">Job Type</InputLabel>
              <Select
                native
                value={state.jobId}
                onChange={handleSelectChange}
                inputProps={{
                  name: 'jobId',
                  id: 'jobId-select'
                }}
              >
                <option aria-label="None" value="" />
                <option value={11}>Trolley</option>
                <option value={21}>Cleaning</option>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="reportType-select" sx={{ marginLeft: 2 }}>
                Report Type
              </InputLabel>
              <Select
                native
                onChange={handleSelectChange}
                inputProps={{
                  name: 'reportType',
                  id: 'reportType-select'
                }}
                sx={{ marginLeft: 2 }}
              >
                <option aria-label="None" value="" />
                <option value="Signoff">Signoff Report</option>
                <option value="Timesheet">Timesheet Report</option>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="startDate-picker-dialog"
                label="Start Date"
                format="dd/MMM/yyyy"
                value={state.startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'Start Date'
                }}
                sx={{
                  width: 170,
                  marginLeft: 2
                }}
              />
              <KeyboardDatePicker
                id="endDate-picker-dialog"
                label="End Date"
                format="dd/MMM/yyyy"
                value={state.endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'End Date'
                }}
                sx={{ width: 170, marginLeft: 2 }}
              />
            </MuiPickersUtilsProvider>
            <Button
              color="primary"
              variant="contained"
              onClick={retrieveReport}
              sx={{ width: 130, marginLeft: 2 }}
              disabled={isRetrieving}
            >
              Get Report
            </Button>
          </Box>
        </Box>
        {isRetrieving ? (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <Box sx={{ pt: 3 }}>
            {state.reportData && state.reportData.length > 0 && (
              <ReportTable
                stores={state.jobId === '11' ? trolleyStores : cleaningStores}
                columns={
                  isReportTypeSignoff()
                    ? signoffColumnList
                    : timesheetColumnList
                }
                data={state.reportData}
                jobType={state.jobId === '11' ? 'Trolley' : 'Cleaning'}
                reportType={state.reportType}
                startDate={toIsoDate(state.startDate).substr(0, 10)}
                endDate={toIsoDate(state.endDate).substr(0, 10)}
              />
            )}
          </Box>
        )}
        <Snackbar
          open={state.alertOpen}
          autoHideDuration={3000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            width: 400,
            '& .MuiAlert-standardError': {
              color: '#fff',
              backgroundColor: '#f00',
              fontWeight: 'bold'
            },
            '& .MuiAlert-standardInfo': {
              color: '#fff',
              backgroundColor: '#00f',
              fontWeight: 'bold'
            },
            '& .MuiAlert-standardError .MuiAlert-icon': {
              color: '#fff'
            }
          }}
        >
          <MuiAlert
            elevation={6}
            onClose={handleAlertClose}
            severity={state.alertSeverity}
          >
            {state.alertMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ReportPage;
