/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unreachable */
/* eslint-disable operator-linebreak */
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  Home as HomeIcon,
  List as ListIcon,
  LogOut as LogOutIcon
} from 'react-feather';
import { logout } from 'src/api/sessionApi';
import { confirmAlert } from 'react-confirm-alert';
import NavItem from './NavItem';
import 'src/style/popup.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const user = {
  avatar: '/static/images/avatars/default-avatar.png',
  jobTitle: 'Chief Executive Officer',
  name: 'Francisco Lee'
};

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();

    console.log('[handleLogout] starts');

    const sessionString = sessionStorage.getItem('session');
    sessionStorage.removeItem('session');

    try {
      const logoutResponse = await logout(sessionString);
      if (!logoutResponse || logoutResponse.error) {
        const errorMessage =
          logoutResponse && logoutResponse.error
            ? logoutResponse.error.code
            : '';
        console.log(`[handleLogout] logout returned error: ${errorMessage}}`);
      }
    } catch (error) {
      console.log('[handleLogout] error: ');
      console.log({ error });
    }

    navigate('/');
    window.location.reload();
  };

  const confirmLogout = async (e) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Do you want to logout?</h1>
            <button
              onClick={() => {
                handleLogout(e);
                onClose();
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                onClose();
              }}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const items = [
    {
      href: '/',
      icon: HomeIcon,
      title: 'Home'
    },
    {
      href: '/app/reports',
      icon: ListIcon,
      title: 'Reports'
    },
    {
      href: '/app/logout',
      icon: LogOutIcon,
      title: 'Logout',
      onclickHandler: confirmLogout
    }
  ];

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onclickHandler={item.onclickHandler}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 200
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 200,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
