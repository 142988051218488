/* eslint-disable import/prefer-default-export */
export const timesheetColumnList = [
  {
    Header: 'Timesheet Report',
    columns: [
      {
        Header: 'Store',
        accessor: 'storeName'
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Staff',
        accessor: 'staffName'
      },
      {
        Header: 'Shift Start',
        accessor: 'shiftStart'
      },
      {
        Header: 'Timesheet Start',
        accessor: 'timesheetStart'
      },
      {
        Header: 'Signoff Start',
        accessor: 'signoffStart'
      },
      {
        Header: 'Shift End',
        accessor: 'shiftEnd'
      },
      {
        Header: 'Timesheet End',
        accessor: 'timesheetEnd'
      },
      {
        Header: 'Signoff End',
        accessor: 'signoffEnd'
      },
      {
        Header: 'Manual Start Reason',
        accessor: 'manualStartReason'
      },
      {
        Header: 'Manual End Reason',
        accessor: 'manualEndReason'
      },
      {
        Header: 'Timesheet Note',
        accessor: 'timesheetNote'
      },
      {
        Header: 'Signoff Note',
        accessor: 'signoffNote'
      }
    ]
  }
];
