/* eslint-disable arrow-body-style */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { login } from 'src/api/sessionApi';
import text from 'src/constants/text';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginPage = ({ setSession }) => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');

  const openAlert = (message) => {
    setAlertOpen(true);
    setAlertMessage(!message ? 'Login request was successful' : message);
    setAlertSeverity(message ? 'error' : 'info');
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
    setAlertMessage('');
  };

  const handleLogin = async (username, password) => {
    setSubmitted(false);
    console.log('[LoginPage.handleLogin] starts');

    try {
      const loginResponse = await login({
        emailOrUsername: username,
        password,
        loginType: 'adminSite'
      });

      if (loginResponse && loginResponse.data) {
        setSession(loginResponse.data);
        console.log('[LoginPage.handleLogin] login was sucessful');
        navigate('/');
      } else if (loginResponse && loginResponse.error) {
        console.log(
          `[LoginPage.handleLogin] login returned error: ${loginResponse.error.code}`
        );
        openAlert('Your login request was not successful!');
      } else {
        console.log('[LoginPage.handleLogin] login returned invalid response');
        openAlert(text.common.generalErrorMessage);
      }
    } catch (error) {
      console.log('[LoginPage.handleLogin] error: ');
      console.log({ error });
      openAlert(text.common.generalErrorMessage);
    }
    setSubmitted(true);
  };

  return (
    <>
      <Helmet>
        <title>Login | Noxan Admin</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              handleLogin(values.username, values.password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3, py: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    textAlign="center"
                  >
                    Noxan Admin
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login with your username and password
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting && !submitted}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
                <div style={{ textAlign: 'center' }}>
                  {isSubmitting && !submitted && <CircularProgress />}
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{
              width: 400,
              '& .MuiAlert-standardError': {
                color: '#fff',
                backgroundColor: '#f00',
                fontWeight: 'bold'
              },
              '& .MuiAlert-standardError .MuiAlert-icon': {
                color: '#fff'
              }
            }}
          >
            <MuiAlert
              elevation={6}
              onClose={handleAlertClose}
              severity={alertSeverity}
            >
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      </Box>
    </>
  );
};

LoginPage.propTypes = {
  setSession: PropTypes.func.isRequired
};

export default LoginPage;
