/* eslint-disable import/prefer-default-export */
import * as moment from 'moment';

export function getSessionId(sessionString) {
  const sessionStringValue = !sessionString
    ? sessionStorage.getItem('session')
    : sessionString;
  const session = JSON.parse(sessionStringValue);

  return session.sessionId;
}

export function toIsoDate(date) {
  return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
}

export function addDays(date, days) {
  return moment(date).add(days, 'days').toDate();
}

export function getLastMonday(date) {
  // if date is sunday, go back to previous week
  const selectedDate = moment(addDays(date, -7)).format('YYYY-MM-DD 00:00:00');
  const monday = moment(selectedDate);

  return monday.day() === 0
    ? monday.add(-1, 'days').day('monday').toDate()
    : monday.day('monday').toDate();
}

export function getLastSunday(date) {
  const sunday = moment(getLastMonday(date)).add(6, 'days');
  return moment(moment(sunday).format('YYYY-MM-DD 23:59:59')).toDate();
}

export function getWeeklyReportStartDate(date) {
  const dayStart = moment(getLastMonday(date)).format('YYYY-MM-DD 00:00:00');
  return moment(dayStart).toDate();
}

export function getWeeklyReportEndDate(date) {
  const dayEnd = moment(getLastSunday(date)).format('YYYY-MM-DD 23:59:59');
  return moment(dayEnd).toDate();
}
