import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import LoginPage from 'src/pages/LoginPage';
import useSession from 'src/hooks/useSession';

const App = () => {
  const routing = useRoutes(routes);
  const { session, setSession } = useSession();

  if (!session) {
    console.log('[App] no session and show login page');
    return <LoginPage setSession={setSession} />;
  }

  console.log('[App] rendering...');
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
