import { useState } from 'react';

export default function useSession() {
  let mounted = true;
  const getSession = () => {
    const sessionString = sessionStorage.getItem('session');
    const session = JSON.parse(sessionString);
    return session;
  };

  const [session, setSession] = useState(getSession());

  const saveSession = (currentSession) => {
    if (mounted) {
      sessionStorage.setItem('session', JSON.stringify(currentSession));
      setSession(currentSession);
    }

    // eslint-disable-next-line no-return-assign
    return () => (mounted = false);
  };

  return {
    setSession: saveSession,
    session
  };
}
