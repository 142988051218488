/* eslint-disable spaced-comment */
//import config from 'src/config.json';
import config from 'src/config.prod.json';
import { getSessionId } from 'src/utils/utils';
import { handleResponse, handleError } from './apiUtils';

const baseUrl = `${config.SERVER_URL}/session`;

export async function login(credentials) {
  const targetUrl = `${baseUrl}/login`;
  return fetch(targetUrl, {
    method: 'POST',
    headers: {
      Authorization: config.BASIC_AUTH,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function logout(sessionString) {
  const targetUrl = `${baseUrl}/logout`;
  return fetch(targetUrl, {
    method: 'POST',
    headers: {
      Authorization: config.BASIC_AUTH,
      sessionId: getSessionId(sessionString),
      'Content-Type': 'application/json'
    }
  })
    .then(handleResponse)
    .catch(handleError);
}
