/* eslint-disable import/prefer-default-export */
export const signoffColumnList = [
  {
    Header: 'Signoff Report',
    columns: [
      {
        Header: 'Store',
        accessor: 'storeName'
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Staff',
        accessor: 'staffName'
      },
      {
        Header: 'Start',
        accessor: 'signoffStart'
      },
      {
        Header: 'End',
        accessor: 'signoffEnd'
      }
    ]
  }
];
